body {
  margin: 1%;
  padding: 0;
  background-color: #1a1a1a; /* Dark grey background */
  color: #f5f5f5; /* Light grey text color */
}

.dropDown { margin-left: 10px }

.container {
  font-family: "Arial", sans-serif;
  background-color: #333;
  color: #f5f5f5;
  max-width: 800px;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
  display: grid;
  place-items: center;
}

.header {
  background-color: #555;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.headerText {
  color: #fff;
  font-size: 24px;
  margin: 0;
}

.tempContainer,
.dropDownContainer,
.locationContainer {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #666;
}

.locationContainer { margin-bottom: 15px }

.weatherContainer {
  display: flex;
  align-items: center;
  padding: 15px;
}

.locationContainer p,
.weatherContainer p,
.tempContainer p {
  margin-left: 10px;
}

.locationText,
.tempText,
.weatherText,
.dropDownText {
  color: #aaa;
  font-size: 16px;
}

.footer {
  text-align: center;
  padding: 15px;
  font-size: 14px;
  color: #666;
}

.footerText {
  margin: 0;
}

.fish-chance-box {
  width: 200px;
  border: 2px solid #0077cc;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #f5f5f5;
}

.header {
  font-size: 18px;
  font-weight: bold;
}

.percentage-display {
  font-size: 36px;
  font-weight: bold;
  color: #0077cc;
  margin: 10px 0;
}

.footer {
  font-size: 12px;
  color: #666;
}

.loading-bar-container {
  width: 80%; /* Less wide */
  height: 10px; /* Slightly taller */
  background-color: #f0f0f0;
  position: relative;
  overflow: hidden;
  margin: 0 auto; /* Center the bar if needed */
}

.loading-bar {
  background-color: #4caf50;
  height: 100%;
  width: 100%;
  position: absolute;
  left: -100%;
  animation: loading 4s linear infinite; /* Slower animation */
}

@keyframes loading {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
